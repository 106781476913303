<template>
  <v-container>
    <h2 class="text-h4">Life and history of Tien-Wei Chang</h2>

    <v-row class="mt-8">
      <v-col cols="12" sm="4">
        <v-img src="~images/chang-tien-wei-5.jpg" contain></v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <h2>Flexible column</h2>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt.
        </p>
        <p>
          Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
          vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
          sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
          eos qui ratione voluptatem sequi nesciunt.
        </p>
      </v-col>
    </v-row>

    <div class="mt-8">
      <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
        odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
        voluptatem sequi nesciunt.
      </p>
      <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui
        blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
        et quas molestias excepturi sint occaecati cupiditate non provident,
        similique sunt in culpa qui officia deserunt mollitia animi, id est
        laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
        distinctio.
      </p>
    </div>

    <v-row class="mt-8">
      <v-col cols="12" sm="6">
        <v-img src="~images/chang-tien-wei-10.jpg"></v-img>
      </v-col>
      <v-col cols="12" sm="6">
        <v-img src="~images/chang-tien-wei-9.jpg" contain></v-img>
      </v-col>
    </v-row>

    <div class="mt-8">
      <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
        odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
        voluptatem sequi nesciunt.
      </p>
      <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui
        blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
        et quas molestias excepturi sint occaecati cupiditate non provident,
        similique sunt in culpa qui officia deserunt mollitia animi, id est
        laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
        distinctio.
      </p>
    </div>

    <v-row class="mt-8">
      <v-col cols="12" sm="6">
        <h2>Flexible column</h2>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt.
        </p>
      </v-col>

      <v-col cols="12" sm="6">
        <v-img src="~images/chang-tien-wei-8.jpg" contain></v-img>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12" sm="6">
        <v-img src="~images/chang-tien-wei-7.jpg"></v-img>
      </v-col>
      <v-col cols="12" sm="6">
        <v-img src="~images/chang-tien-wei-4.jpg" contain></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Life",
};
</script>
